<template>
  <router-view></router-view>
</template>

<script>

import createAuthRefreshInterceptor from 'axios-auth-refresh'
import TDHLoginOverlay from './views/tdh/TDHLoginOverlay'
import '@/assets/scss/components/_tdhmodal.scss'

export default {
  name: 'App',
  created () {
    const vm = this

    // at the moment we only show the login screen for any failed 401/403 request
    // TODO: handling for 5xx errors (no login, display "retry")
    const refreshAuthLogic = failedRequest =>
      vm.$modal(TDHLoginOverlay)
        .then(tokenRefreshResponse => {
          return Promise.resolve()
        })

    // initialize the refresh interceptor
    createAuthRefreshInterceptor(vm.axios
      , refreshAuthLogic
      , {
        statusCodes: [401],
        pauseInstanceWhileRefreshing: false // this should be false to catch all failed requests
      })

    // update token for every request (in case of relogin)
    vm.axios.interceptors.request.use(request => {
      request.headers['X-CSRFToken'] = getCookie('csrftoken')
      request.headers['X-Timezone'] = vm.$config.timezone
      return request
    })
    // intercept sucessful request for updating lastRequestTimestamp
    vm.axios.interceptors.response.use(
      function (response) {
        vm.$store.commit('set_last_request_timestamp', [Date.now()])
        return response
      }
    )
    /**
     * helper method for extracting cookie data
     * @param cname
     * @returns {string}
     */
    function getCookie (cname) {
      const name = cname + '='
      const ca = decodeURIComponent(document.cookie).split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    }
  },
  mounted () {
    // setInnerTabHeight()
    // window.addEventListener('resize', setInnerTabHeight)
    // function setInnerTabHeight() {
    //   console.log("Resize of window!")
    //   const tabContent = document.querySelector('.router-tab .tab-content')
    //   if(tabContent) {
    //     const tabHeight =  (window.innerHeight - tabContent.getBoundingClientRect().top - 80)+'px'
    //     tabContent.style.height = tabHeight
    //   }
    //
    // }
  }

}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
</style>
