module.exports = {
  rest_url: '/api',
  reference_path: '/ui/content/',
  timeout: 3 * 60 * 60 * 1000,
  event_daterule_polling_time: 1500,
  date_time_format: 'YYYY-MM-DDTHH:mm:ssZZZZ',
  time_format: 'HH:mm:ssZZZZ',
  timezone: 'Europe/Berlin',
  google_api_key: 'AIzaSyBV7llHLMG8zrpFzPkAy5lJPrzcX2gxUtI',
  assetURL: urlFromIMS => ['https://assets.tourism-data-hub.de', urlFromIMS.replace(/^\/?res\//, '')].join('/'),
  devClick: false,
  axiosAdapter: {
    ttl: 20 * 1000
  },
  dummyIDs: [999996, 999999, 999998, 999997, 999995, 999994, 999993, 999992, 999991, 999990, 999989, 999988],
  iconmapping: {
    contenttype: {
      place: 'location-pin',
      contact: 'contact',
      event: 'calendar'
      // classification: '',
      // attribute: ''
    },
    prefix: 'cil'
  },
  colormapping: {
    contenttype: {
      place: 'location-pin',
      contact: 'contact',
      event: 'calendar'
    }
  },
  searchfieldmapping: {
    contenttype: {
      event: {
        start: 'event_date'
      }
    }
  },
  listTitles: {
    Event: 'Events',
    Place: 'Standorte',
    Contact: 'Kontakte',
    File: 'Dateien',
    User: 'Nutzer',
    Role: 'Rollen',
    Group: 'Gruppen',
    Permission: 'Berechtigungen',
    ACL: 'ACL',
    Collection: 'Kollektionen',
    ContentToCollection: 'Inhalte zu Kollektionen'
  },
  editorOptions: {
    link: 'link'
  }
}
