'use strict'

const hostname = location.hostname.match(/^(?:((.*\.)?(dev\.)|testing\.|(?:.+\.)?staging\.|preview\.|www\.))?tourism-?data-?hub\.de$/)
// tourism-data-hub.de => [ "tourism-data-hub.de", undefined, undefined, undefined ]
// www.tourism-data-hub.de => [ "www.tourism-data-hub.de", "www.", undefined, undefined ]
// staging.tourism-data-hub.de => [ "staging.tourism-data-hub.de", "staging.", undefined, undefined ]
// dev.tourism-data-hub.de => [ "dev.tourism-data-hub.de", "dev.", undefined, "dev." ]
// andreas.dev.tourism-data-hub.de ==> [ "andreas.dev.tourism-data-hub.de", "andreas.dev.", "andreas.", "dev." ]

const config = {
  version: process.env.VUE_APP_VERSION
}

function mergeConfig (additional) {
  for (const k in additional) config[k] = additional[k]
}

mergeConfig(require('./production'))
if (location.hostname === '192.168.100.51') mergeConfig(require('./development-sebastian2'))

if (hostname) {
  if (hostname[1] === 'staging.') mergeConfig(require('./staging'))
  if (hostname[1] === 'preview.') mergeConfig(require('./preview'))
  if (hostname[1] === 'testing.') mergeConfig(require('./testing'))
  if (hostname[3]) mergeConfig(require('./development'))
  if (hostname[2] === 'andreas.') mergeConfig(require('./development-andreas'))
  if (hostname[2] === 'jan.') mergeConfig(require('./development-jan'))
  if (hostname[2] === 'jens.') mergeConfig(require('./development-jens'))
  if (hostname[2] === 'lukas.') mergeConfig(require('./development-lukas'))
  if (hostname[2] === 'sebastian.') mergeConfig(require('./development-sebastian'))
  if (hostname[2] === 'sebastian2.') mergeConfig(require('./development-sebastian2'))
}

export default Object.freeze(config)
