
export default function () {
  window.addEventListener(
    'click',
    evt => {
      // only ALT and CTRL together make secret magic spell
      if (!evt.altKey /* || !evt.ctrlKey */) return // CTRL not working on Mac

      // stop normal event handling
      evt.stopImmediatePropagation()
      evt.preventDefault()

      // remove previously added popup on magic click
      if (evt.target._isDevPopup_yYBiZ3dN) {
        evt.target.remove()
        return
      }

      // find (closest) id and testid
      const slideUp = (elem, get) => {
        while (!get(elem) && elem.parentNode) elem = elem.parentNode
        return get(elem)
      }
      const id = slideUp(evt.target, elem => elem.id)
      const testid = slideUp(evt.target, elem => elem.dataset && elem.dataset.testid)

      // show infos in popup
      const p = { x: evt.clientX, y: evt.clientY }
      const d = {
        x: window.innerWidth - evt.clientX,
        y: window.innerHeight - evt.clientY
      }
      const off = v => (v - 4) + 'px'
      const popup = document.createElement('pre')
      Object.assign(popup, {
        _isDevPopup_yYBiZ3dN: true,
        innerHTML: '    id = ' + id + '\n' + 'testid = ' + testid
      })
      Object.assign(popup.style,
        {
          position: 'absolute',
          zIndex: 99999,
          margin: 0,
          border: 'solid 1px #000',
          backgroundColor: '#fff',
          padding: '4px'
        },
        p.x < d.x ? { left: off(p.x) } : { right: off(d.x) },
        p.y < d.y ? { top: off(p.y) } : { bottom: off(d.y) }
      )
      document.querySelector('body').appendChild(popup)
    },
    { capture: true }
  )
}
