<template>
  <modal-layout :class="{confirmed: confirm}" :hide-closer="true" :prevent-backdrop-click="true" class="login-overlay-view">
    <div class="relog">
      <CCard>
        <CCardBody>
          <CCardBody>
            <CForm @submit.prevent="submit">
              <h2>{{ message }}</h2>
              <p class="text-muted">Bitte melden Sie sich an</p>
              <p :class="{pwerror: pwerror}" class="text-danger warningtext">Benutzername oder Passwort inkorrekt</p>
              <CInput
                v-model="username"
                autocomplete="username email"
                autofocus
                placeholder="Benutzername"
                data-testid="login-modal-username"
              >
                <template #prepend-content>
                  <CIcon name="cil-user"/>
                </template>
              </CInput>
              <CInput
                v-model="password"
                placeholder="Passwort"
                type="password"
                data-testid="login-modal-password"
              >
                <template #prepend-content>
                  <CIcon name="cil-lock-locked"/>
                </template>
              </CInput>
              <CRow>
                <CCol class="text-left" col="6">
                  <CButton class="px-4" color="primary" type="submit" data-testid="login-modal-button">Login</CButton>
                </CCol>
                <CCol class="text-right" col="6">
                  <CButton class="px-0" color="link" data-testid="login-modal-password-forgotten">Passwort vergessen?</CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>

        </CCardBody>
      </CCard>
    </div>
  </modal-layout>
</template>

<script>

/**
 * This overlay is displayed when an axios request returns a 401/403.
 * An interceptor is used to queue those requests until the user is authenticated again using this dialog
 */

'use strict'
import axios from 'axios'

export default {
  name: 'TDHLoginOverlay',
  created () {
    this.$store.commit('set', ['activeLoginScreen', true])
  },
  destroyed () {
    this.$store.commit('set', ['activeLoginScreen', false])
  },
  components: {},
  data () {
    return {
      visible: false,
      confirm: false,
      pwerror: false,
      username: '',
      password: '',
      message: ''
    }
  },
  methods: {
    /**
     * display the login window
     * on success we resolve the promise for our interceptor which retrys the stalled requests
     * @returns {*}
     */
    getLogin () {
      const vm = this
      const user = { username: this.username, password: this.password }
      const api = vm.$config.rest_url
      const axiosInstance = axios.create({})
      return axiosInstance({
        url: api + '/user/login/',
        data: user,
        skipAuthRefresh: true,
        headers: { 'X-CSRFToken': vm.getCookie('csrftoken') },
        method: 'POST',
        withCredentials: true
      }).then(function (resp) {
        vm.pwerror = false
        const user = resp.data
        if (vm.$store.getters.getUser.user.id !== user.user.id) {
          vm.$router.push('/dashboard')
            .finally(() => {
              vm.$tabs.reset('/dashboard')
              vm.$store.commit('entries/resetState')
              vm.$store.commit('additionaldata/resetState')
              vm.$store.commit('contenttypes/resetState')
              vm.$store.commit('schemas/resetState')
            })
        }
        vm.$store.commit('auth_success', user)
        vm.$store.commit('set', ['activeLoginScreen', false])
        return Promise.resolve(resp)
      })
        .catch(function (err) {
          vm.pwerror = true
          vm.$store.commit('auth_error')
          return Promise.reject(err)
        })
    },
    /**
     * helper method to extract cookie data
     * @param cname
     * @returns {string}
     */
    getCookie (cname) {
      const name = cname + '='
      const decodedCookie = decodeURIComponent(document.cookie)
      const ca = decodedCookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    },
    /**
     * cancel the relogin and close the window
     */
    cancel () {
      this.$emit('close', false)
    },
    /**
     * submit request and close login overlay after sucessful relogin
     */
    submit () {
      this.getLogin().then(() => {
        this.$emit('close', true)
      }).catch(
        () => {
          console.log('Relogin Error')
        }
      )
    }
  }
}
</script>

<style>

.warningtext {
  display: none;
}

.warningtext.pwerror {
  display: block;
}

.login-overlay-view.confirmed {
  pointer-events: all;
  display: block;
}

.login-overlay-view_ .relog {
  position: absolute;
  top: 25%;
  left: 50%;
  /*transform: translateX(-50%) translateY(-50%);*/
  opacity: 1;
  z-index: 8889;
}

.login-overlay-view_ .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 8888;

  background: #000;
  opacity: 0;
  /*-webkit-animation: SlowMo 5s cubic-bezier(0.77, 0, 0.175, 1) infinite;*/
  -webkit-transition: opacity 800ms cubic-bezier(0.77, 0, 0.175, 1);
}

.login-overlay-view.confirmed .overlay {
  opacity: 0.6;
}

@-webkit-keyframes SlowMo {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}
</style>
