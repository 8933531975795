import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import config from '@/config'

// Views
// const Dashboard = () => import('@/views/Dashboard')
const Dashboard = () => import('@/views/DashboardNew')
// import DashboardNew from '@/views/DashboardNew'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - TDH

// const PoiList = () => import('@/views/base/PoiList')
// const List = () => import('@/views/base/List')
// const TDHListFilter = () => import('@/views/base/TDHListFilter')
const TDHDetail = () => import('@/views/tdh/TDHDetail')
const TDHTable = () => import('@/views/tdh/TDHTable')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
// const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
// const Register = () => import('@/views/pages/Register')

Vue.use(Router)
const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      // redirect: 'dashboard',
      name: 'Home',
      meta: {
        title: 'Dashboard',
        requiresAuth: true
      },
      component: TheContainer,
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            title: '',
            tabClass: ['tdh-dashboard'],
            closable: false,
            icon: 'cil-home'
          }
        },
        {
          path: '/edit/:content/:id',
          currentContent: ':content',
          name: 'Edit',
          component: TDHDetail,
          props: true,
          meta: {
            title: route => `${(route.params.name !== undefined) ? route.params.name : route.params.id}`,
            key: 'path',
            tabClass: ['tdh-edit'],
            icon: route => config.iconmapping.prefix + '-' + config.iconmapping.contenttype[`${route.params.content.toLowerCase()}`]
          }
        },
        {
          path: '/list/:content_type',
          name: 'List',
          component: TDHTable,
          props: true,
          meta: {
            title: route => `Liste: ${config.listTitles[route.params.content_type] ? config.listTitles[route.params.content_type] : route.params.content_type}`,
            key: 'path',
            class: 'TDHListTab',
            tabClass: ['tdh-list'],
            icon: 'cil-list'
          }
        },
        {
          path: '/404',
          name: 'Page404',
          component: Page404,
          meta: {
            title: 'Page Not Found',
            key: 'path'
          }
        }
      ]
    },
    // {
    //   path: '/pages',
    //   redirect: '/pages/404',
    //   name: 'Pages',
    //   component: {
    //     render (c) { return c('router-view') }
    //   },
    //   children: [
    //     {
    //       path: '404',
    //       name: 'Page404',
    //       component: Page404
    //     },
    //     {
    //       path: '500',
    //       name: 'Page500',
    //       component: Page500
    //     },
    //     {
    //       path: 'register',
    //       name: 'Register',
    //       component: Register
    //     }
    //   ]
    // },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        isLogin: true
      }
    }
  ]
})

// commented out because of logout-issues, redundant at this point (communicated to Sebastian)

router.beforeEach((to, from, next) => {
  // if we want to go to the login-page
  if (to.matched.some(record => record.meta.isLogin)) {
    if (store.getters.isLoggedIn === true) {
      next({ name: 'Dashboard' })
    } else {
      next()
    }
  // if the route requires auth
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    // if we are logged in and the login timestamp is not too old
    if (store.getters.isLoggedIn === true && store.getters.checkLoginTimestamp) {
      next()
    } else {
      next({ name: 'Login' })
    }
  }
})

router.afterEach((to, from) => {

})

export default router
