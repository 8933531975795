// browser compatibility
import 'core-js/stable'
import 'element-closest-polyfill' // IE11
import 'url-polyfill'

// async XHR request
import axios from 'axios'

// Leaflet
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

// Validation
import ajvInstance from './util/TDHAjv'

// Vue libraries
import Vue from 'vue'
import VueAxios from 'vue-axios'
import RouterTab from 'vue-router-tab'
import VueI18n from 'vue-i18n'
import LastModal from 'vue-last-modal'
import VCalendar from 'v-calendar'
import VueObserveVisibility from 'vue-observe-visibility'
import VueEasyLightbox from 'vue-easy-lightbox'

// CoreUI libraries
import CoreuiVue from '@coreui/vue-pro/src'
import { linearSet, solidSet, duotoneSet } from '@coreui/icons-pro'

// Application
import config from './config'
import router from './router'
import store from './store'
import App from './App'
import getTDHAxiosAdapter from './util/TDHAxiosAdapter'
import devClick from './util/devClick'
import jq from './lib/jqjs/jq'

// load script
// import LoadScript from 'vue-plugin-load-script'
import TDHGoogleMapsApiLoaderPlugin from './views/tdh/plugins/TDHGoogleMapsApiLoaderPlugin'

// Mixins
import TDHApiMixin from './views/tdh/mixins/TDHApiMixin'
import TDHComponentPathMixin from './views/tdh/mixins/TDHComponentPathMixin'

config.devClick && devClick()

delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

Vue.prototype.$config = config
Vue.prototype.$log = console.log.bind(console)
Vue.prototype.$jq = jq
Vue.prototype.$ajv = ajvInstance

// Vue.use(LoadScript)
Vue.use(TDHGoogleMapsApiLoaderPlugin, { apiKey: config.google_api_key })
Vue.use(VueEasyLightbox)

Vue.use(VueAxios, axios.create({
  adapter: getTDHAxiosAdapter(config)
}))
Vue.use(RouterTab)
Vue.use(CoreuiVue)
Vue.use(VueI18n)
Vue.use(VueObserveVisibility)
Vue.use(LastModal)

Vue.use(VCalendar, {
  componentPrefix: 'vc' // Use <vc-calendar /> instead of <v-calendar />
})

Vue.mixin(TDHApiMixin)
Vue.mixin(TDHComponentPathMixin)

// TODO: move messages object to own file
const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'en',
  messages: {
    en: {
      validations: {
        required: 'This field is required',
        maxLength: 'maximum text-length reached',
        url: 'must match format "url"',
        noDummy: 'This field should not contain Dummy-Elements',
        sameAs: 'This field must be checked'
      }
    },
    de: {
      validations: {
        required: 'Pflichtfeld, Bitte treffen Sie eine Eingabe!',
        maxLength: 'maximale Text-Länge erreicht!',
        url: 'muss diesem Format entsprechen: "url"',
        noDummy: 'Dieses Feld sollte kein Dummy-Element enthalten',
        sameAs: 'Dieses Feld muss angeklickt sein.'
      }
    }
  }
})

new Vue({ // eslint-disable-line no-new
  el: '#app',
  router,
  store,
  i18n,
  icons: { ...linearSet, ...duotoneSet, ...solidSet },
  beforeCreate () {
    const vm = this
    vm.$store.commit('initialiseStore')
    vm.$store.commit('setImsVersion')
  },
  created () {
  },
  template: '<App/>',
  components: {
    App
  }
})
